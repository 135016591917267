import React from 'react';

const FooterStoreLocator = () => {
  let zipcodeInput = '';

  const handleZipcode = (e) => {
    zipcodeInput = e.target.value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = `https://www.raleys.com/stores?q=${zipcodeInput}`;
    return null;
  };
  // console.log(window.location.href);
  return (
    <>
      <ul className="footer-list-items w-list-unstyled">
        <li className="footer-title" data-testid="title">
          Find A&nbsp;Store
        </li>
      </ul>
      <div className="zip-code-input-block">
        <div className="zip-code-input w-embed">
          <form onSubmit={handleSubmit}>
            <input
              style={{
                width: '100%',
                background: '#313030',
                borderRadius: '0px',
                border: '1px solid #fff',
                padding: '8px 12px',
                fontWeight: 100,
              }}
              placeholder="Enter Zip Code"
              name="zip"
              id="zip-code-input-id"
              data-testid="zip-code-input"
              onChange={handleZipcode}
            />
          </form>
        </div>
        <img
          src="https://assets.website-files.com/6063cd65aa6a07d366c44ab1/6068b32973bd5ed166beeecf_search.svg"
          loading="lazy"
          alt="search"
          className="search-svg"
          data-testid="search-image"
        />
      </div>
    </>
  );
};

export default FooterStoreLocator;
